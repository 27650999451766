export const addDateToSortByField = posts => {
  return posts.map(({ node: post }) => {
    const createdAtDate = new Date(post._createdAt)

    let editableDate = post.publishedDate ? new Date(post.publishedDate) : null

    // if (post.publishedDate) {
    //   editableDate = new Date(post.publishedDate)
    // } else {
    //   editableDate = null
    // }

    post.dateToSortBy = editableDate || createdAtDate

    post.dateString = `${post.dateToSortBy.getDate()}-${
      monthArray[post.dateToSortBy.getMonth()]
    }-${post.dateToSortBy.getFullYear()}`

    return post
  })
}

export const sortPostByDate = posts => {
  return posts.sort((a, b) => b.dateToSortBy - a.dateToSortBy)
}

export const monthArray = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
]

export const monthArrayFull = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

export const formatDateFull = dateObject => {
  const day = dateObject.getDate()
  const month = monthArrayFull[dateObject.getMonth()]
  const year = dateObject.getFullYear()
  return `${month} ${day}, ${year}`
}

export const formatTime = dateTimeObject =>
  Intl.DateTimeFormat("en", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  }).format(dateTimeObject)

// reshape data to work with EventCard component

export const reshapeEventData = eventData => {
  return eventData.map(event => {
    let date = new Date(event.eventDateTime)
    const day = date.getDate()
    const year = date.getFullYear()
    const month = monthArray[date.getMonth()]
    const eventDescription = event?.bannerText || "No Description Provided"
    const slug = event?.slug?.current || null
    const eventImage = event?.bannerHeroImage?.asset?.gatsbyImageData || null
    // const minutes = date.getMinutes()
    // const hours = date.getHours()
    const formattedDate = date.toLocaleDateString("en-gb", {
      year: "numeric",
      month: "short",
      day: "numeric",
    })

    const eventTime = formatTime(date)
    // let eventTime = ""
    // eventTime = `${hours}:${minutes}`
    return {
      id: event.id,
      title: event.title,
      eventTime,
      day,
      year,
      month,
      eventDate: formattedDate,
      dateObject: date,
      eventDescription,
      slug,
      registrationLink: event.registrationLink,
      eventImage,
    }
  })
}
