import React from "react"
import { graphql } from "gatsby"
import { convertToBgImage } from "gbimage-bridge"

import Layout from "../components/layout"
import SEO from "../components/SEO"
import Hero, { HeroTitleDescription } from "../components/Hero"
import BannerTitle from "../components/bannerTitle"
import Divider from "../components/divider"
import NewsCard from "../components/news/newsCard"
import BannerContainer from "../components/bannerContainer"
import PageContentContainer from "../components/PageContentContainer"
import { addDateToSortByField, sortPostByDate } from "../helperFunctions"
import { getGatsbyImageData } from "gatsby-source-sanity"
import { sanityConfig } from "../sanityConfig"
import { getImage } from "gatsby-plugin-image"
import RenderNewsCards from "../components/news/RenderNewsCards"
import RenderNewsPosts from "../components/index/RenderNewsPosts"

const News = ({ data }) => {
  const {
    newsPosts: { edges: newsPosts },
  } = data

  const defaultPostImage = data.defaultPostImage.childImageSharp.gatsbyImageData
  // const heroFluidData = getGatsbyImageData(
  //   heroImage.bannerHeroImage.asset.id,
  //   { maxWidth: 4100 },
  //   sanityConfig
  // )

  const bgImage = convertToBgImage(defaultPostImage)

  // Create a deep copy of the array without references using JSON methods
  // https://www.freecodecamp.org/news/how-to-clone-an-array-in-javascript-1d3183468f6a/
  const postsArrayCopy = JSON.parse(JSON.stringify(newsPosts))
  // Add a new field to post object to sort by date
  const arrayWithNewDateField = addDateToSortByField(postsArrayCopy)
  // Sort by the new date field
  const sortedPosts = sortPostByDate(arrayWithNewDateField)

  // Currently News posts use a default image defined in this file

  return (
    <Layout>
      <SEO title="News" />

      <Hero
        props={{
          heroFluid: bgImage.fluid,
        }}
      >
        <HeroTitleDescription
          title="What's New"
          text="Stay in the know about what’s happening at W.I.N.K.S."
        />
      </Hero>
      <PageContentContainer>
        {/* <RenderNewsCards posts={sortedPosts} /> */}
        <BannerContainer color="bg-secondary" className="py-20">
          <div className="flex flex-col gap-y-5">
            <RenderNewsPosts lightTitle={false} />
          </div>
        </BannerContainer>
      </PageContentContainer>
    </Layout>
  )
}

export const query = graphql`
  query NewsPageQuery {
    defaultPostImage: file(
      relativePath: { eq: "macau-photo-agency-O8KXb87sTZs-unsplash 1.png" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(height: 500, placeholder: BLURRED)
      }
    }
    newsPosts: allSanityBlogPost(
      sort: { fields: [publishedDate, _createdAt], order: [DESC, DESC] }
    ) {
      edges {
        node {
          _createdAt
          publishedDate
          title
          summary
          slug {
            current
          }
          featureImage {
            asset {
              id
              url
              gatsbyImageData(width: 1200, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`

export default News
